import React from 'react' // eslint-disable-line no-unused-vars
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'gatsby'
import { LunrSearch } from './LunrSearch' // eslint-disable-line no-unused-vars
import navLeft from '../../content/navLeft.yml'
import navRight from '../../content/navRight.yml'
import logo from './symbion-logo.svg'
import headbg from './header.png'
import headbg2 from './header-small.png'
import headwrap from './headerbg.png'

const style = {
	headwrap: css`
		background-image: url(${headwrap});
		background-repeat: no-repeat;
		background-size: 100% 100%;
	`,
	head: css`
		background-image: url(${headbg});
		background-repeat: no-repeat;
		background-size: auto 100%;
		@media (max-width: 800px) {
			background-image: url(${headbg2});
		}
	`,
	title: css`
		margin: 0;
		display: inline-block;
	`,
	logo: css`
		height: 110px;
		overflow: visible;
		transition: 2s;
	`,
	logowrap: css`
		width: 187px;
		height: 110px;
		z-index: 10;
	`,
}
/* eslint-disable no-unused-vars */
const TitleLink = styled(Link)`
	color: #fff;

	&:active,
	&:hover {
		color: #fff;
	}
`

interface HeaderProps {
	readonly title: string
}

const Navbar = ({ items }) => (
	<>
		{items &&
			items.map &&
			items.map((link) => (
				<Link key={link.title} title={link.title} to={link.to}>
					{link.title}
				</Link>
			))}
	</>
)

const Logo = () => {
	return (
		<div css={style.logowrap}>
			<object data={logo} type="image/svg+xml" css={style.logo}></object>
		</div>
	)
}
const MLogo = React.memo(Logo)
/* eslint-enable no-unused-vars */

//					className={location.pathname.includes(link.activeSelector) && "active" }
export const Header = ({ title }: HeaderProps) => (
	<header>
		<section css={style.headwrap}>
			<section css={style.head}>
				<MLogo />
				<h1 css={style.title}>
					<TitleLink to="/">{title}</TitleLink>
				</h1>
				<LunrSearch limit={10} />
			</section>
		</section>
		<nav>
			<input type="checkbox" />
			<span></span>
			<span></span>
			<span></span>
			<div>
				<Navbar items={navLeft.items} />
				<div className="spacer"></div>
				<Navbar items={navRight.items} />
			</div>
		</nav>
	</header>
)

import React from 'react' // eslint-disable-line no-unused-vars
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet' // eslint-disable-line no-unused-vars
import { SiteMetadataQuery } from 'generated/types/gatsby' // eslint-disable-line no-unused-vars
import { Header } from './Header' // eslint-disable-line no-unused-vars
import './global.scss'
import favicon from './favicon.gif'

interface LayoutProps {
	readonly children?: React.ReactNode | readonly React.ReactNode[]
	readonly path?: String
	readonly date?: String
}

export const Layout = ({ children, path, date }: LayoutProps) => {
	const data = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(graphql`
		query SiteMetadata {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`)

	return (
		<>
			<Helmet
				titleTemplate={`%s - ${data.site.siteMetadata.title}`}
				defaultTitle={data.site.siteMetadata.title}
				meta={[
					{
						name: 'description',
						content: data.site.siteMetadata.description,
					},
					{
						name: 'keywords',
						content: 'gatsby, gatsbyjs, sample, demo, typescript',
					},
				]}
			>
				<link rel="icon" href={favicon} />
			</Helmet>
			<Header title={data.site.siteMetadata.title} />
			<main>
				<main>{children}</main>
			</main>
			<footer>
				<div>
					<span>© 1999-{new Date().getFullYear()} Symbion</span>
					<span>{path && path.match(/^.news/) ? date : ''}</span>
				</div>
			</footer>
		</>
	)
}
